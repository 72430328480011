<style lang="scss" scoped>
.modal-content {
  padding: 0;
  overflow: hidden;
  width: 100%;
  background-color: white;
}
.zen-chef {
  min-height: 100%;
  min-width: 100%;
}
</style>

<template>
  <header class="modal-header">
    <h1 class="modal-title">Réserver en ligne</h1>
    <p class="modal-subtitle">en partenariat avec ZenChef</p>
  </header>
  <main class="modal-content">
    <iframe
      title="Book online with ZenChef"
      class="zen-chef"
      src="https://bookings.zenchef.com/results?rid=354741&fullscreen=1&lang=fr&primaryColor=36464E"
    ></iframe>
  </main>
  <footer class="modal-footer" @click="$router.go(-1)">
    <span>Fermer</span>
  </footer>
</template>

<script>
export default {};
</script>
